.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  padding: 0px;
  margin: 0px;
  font-family: 'Courier New';
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bm-burger-button {
  z-index: -0 !important;
  height: 16px !important;
  width: 25px !important;
}

@media print {
  @page {
    size: 4in 6in;
    margin: 20px;
  }
}

fieldset {
  margin-bottom: 10px;
  margin-top: 10px;
  border-color: #808080;
  border-width: 1px;
  background-color: #f0f0f0;
  min-height: 50px;
}

legend {
  color: #808080;
}

button {
  border-radius: 5px;
  border-style: solid;
  border-color: #808080;
  background-color: #ffffff;
  color: #000;
  border-width: 1px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 15px
}

h3 {
  margin: 5px;
}

h2 {
  margin: 5px;
}


.navigation-bar {
  background-color: #ffffff;
  border-bottom: 1px solid #c0c0c0;
  width: 100%
}

.navigation-bar-content {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 750px;
  margin: auto;
  flex-wrap: wrap;
}

.content {
  margin: auto;
  max-width: 750px;
  margin-bottom: 100px;
}

.div-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.div-row-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.div-row-space-between {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

h4 {
  margin: 0px;
  text-align: center;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}


.div-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.button-invisible {
  border: none;
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

textarea {
  font-size: 17px;
}

.btn-primary-1 {
  background-color: orange;
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
}

.div-table {
  width: 100%;
  max-width: 750px;
  border-top: 1px solid #000000;
}

.div-table-row-2 {
  border-bottom: 1px solid #000000;
  padding: 5px 10px;
}


.div-table-row {
  border-bottom: 1px solid #000000;
  flex-direction: row;
  display: flex;
}

.div-table-col {
  padding: 5px;
  flex: 1;
}

.btn-notify-me {
  background-color: #FCD000;
  color: #000000;
}

.btn-invisible {
  border: none;
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.fetching-div {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
}

.badge_ {
  background-color: #FCD000;
  color: #000000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  width: fit-content;
  display: flex;
  align-items: center;
}

.badge_-success {
  background-color: green;
  color: #ffffff;
}

.badge_-2 {
  background-color: green;
  color: #ffffff;

}

.badge_-danger {
  background-color: red;
  color: #ffffff;
}

.badge_-3 {
  background-color: red;
  color: #ffffff;

}

.date-picker-custom {
  max-width: 100px;
}

.select-custom {
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  font-size: 13px;
}

.btn-undo {
  background-color: #c0c0c0;
  color: #808080;
  border-color: #909090;
}

.btn-1 {
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="text"] {
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  font-size: 17px;
}

select {
  font-size: 17px;
}


.confirmation-message {
  background-color: green;
  width: fit-content;
  padding: 5px 25px;
  margin: 5px;
  color: #ffff;
  border-radius: 5px;
  font-size: 13px;
}

a {
  color: blue;
}

.container-main {
  max-width: 650px;
  margin-top: 70px;
  padding: 0px;
}